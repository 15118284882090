<template>
    <Datepicker
        ref="datepicker"
        :range="range"
        v-model="date" 
        :markers="markers" 
        :disabledDates="disabledDates" 
        :minDate="new Date()"
        :placeholder="placeholder"
        @updateMonthYear="handleMonthYear($event)"
        @open="handleOpen"
        :inline="inline"
    >
    <template #marker-tooltip="{ tooltip }">
      <div @scroll.stop>
      <h1>RESERVED</h1>
      <div>
        <p>item: <b>{{tooltip.item.name}}</b></p>
        <p style="color:darkgrey"><small>{{tooltip.block.itemid}}</small></p>
        <p>notes: {{tooltip.block.notes}}</p>
        <p style="color:darkgrey">by: {{tooltip.block.confirmedby}} </p>
        <p><small>from {{tooltip.block.from}} till {{tooltip.block.till}}</small></p>
        <p style="color:darkgrey"><small>{{tooltip.block.id}}</small></p>
      </div>
    </div>
    </template>
    </Datepicker>
</template>

<style>
.dp__marker_tooltip{
  width: min-content;
  min-width: 40vw;
  max-width: 49vw;
  overflow:auto;
}
.dp__tooltip_text{
  justify-content: center;
}
.dp__tooltip_content{
  width: 100%;
  white-space: normal;
}
.dp__tooltip_mark{
  display: none;
}
</style>
  
<script>
import { computed, ref, watch } from '@vue/runtime-core';
import { useStore } from 'vuex';
  
  
export default {
name: 'Calendar',
props:[
    'items2reserve',
    'inline',
    'placeholder',
    'readonly',
    'range',
    'hasDeleteButton',
    'update'
],
emits:[
    'dateUpdate',
    'deleteReservation'
],
setup(props, { emit }){
    const store = useStore();

    const date = ref(null);

    const datepicker = ref(null);

    watch(computed(()=>props.update),()=>{
      console.log("update triggered");
      datepicker.value.closeMenu();
    })

    watch(computed(()=>date.value),()=>{
        emit('dateUpdate',date.value)
    })

    watch(computed(()=>props.items2reserve),()=>{
        fillReservations();
    })

    let now = new Date();
    let nowMonth = now.getMonth();
    let nowYear = now.getFullYear();

    let monthSelected = nowMonth;
    let yearSelected = nowYear;

    const markers = ref([]);
    const disabledDates = ref([]);

    const fillReservations = async () => {
      markers.value = [];
      disabledDates.value = [];
      for (let item of props.items2reserve){
        let {reservations} = await store.dispatch("getReservations",{item:item.id})
        if (!reservations || reservations.length < 1) continue //console.log("no reservations so far for ",item.id)
        reservations.forEach((block)=>{ // FIXME: use better endpoint and get complete_reservations or sth. then use reservationname
          if(!block.days) return
          block.from = block.reserved[0].split(" ")[0];
          block.till = block.reserved[1].split(" ")[0];
          block.days.forEach((day)=>{
            let date = new Date(day);
            let dateSelected = new Date(yearSelected, monthSelected,15);
            let maxDate = new Date(dateSelected).setDate(dateSelected.getDate() + 30)
            let minDate = new Date(dateSelected).setDate(dateSelected.getDate() - 30)
            if (date > maxDate || date < minDate) return //console.log("date outside of range. will not display yet")
            markers.value.push(
            {
              date: day,
              type: 'line',
              tooltip: [
                { 
                  // html: `
                  // <div>
                  //   <h1>RESERVED</h1>
                  //   <div>
                  //     <p>item: <b>${item.name}</b></p>
                  //     <p style="color:darkgrey"><small>${block.itemid}</small></p>
                  //     <p>notes: ${block.notes}</p>
                  //     <p style="color:darkgrey">by: ${block.confirmedby} </p>
                  //     <p><small>from ${block.from} till ${block.till}</small></p>
                  //     <p style="color:darkgrey"><small>${block.id}</small></p>
                  //     <!--
                  //     <button onclick="
                  //       document.dispatchEvent(new CustomEvent('deleteReservation',
                  //       {detail:{id:'${block.id}',itemid:'${block.itemid}'},bubbles:false,cancelable:true}))"
                  //     >
                  //     delete
                  //     </button>
                  //   </div>
                  // </div>
                  // -->
                  // `, 
                  text:'test',
                  item:item,
                  block:block,
                  color: 'red' },
              ],
            })
            disabledDates.value.push(
              day
            )
          })
        })
      }   
    }

    const handleMonthYear = async ({month, year }) => {
      if (month != monthSelected || year != yearSelected){
        monthSelected = month; yearSelected = year;
        await fillReservations();
      }
    }

    const handleOpen = async () => {
      if (monthSelected == nowMonth && yearSelected == nowYear) return
      monthSelected = nowMonth;
      yearSelected = nowYear;
      await fillReservations();
    }


    const handleDeleteReservation= async (e)=>{
      // e.preventDefault();
      // e.stopImmediatePropagation(); // dont work for unknown reason. bubbling the other work?

      console.log('Delete Reservation from calendar',e.detail.id);
      //emit("deleteReservation",e.detail);
      console.log("trying to delete reservation",e.detail.id)
      await store.dispatch('deleteReservation',e.detail);
      fillReservations();
      if (datepicker) datepicker.value.closeMenu()
      // continue here
      
    }

    document.addEventListener('deleteReservation',handleDeleteReservation);

    return {
        date,
        markers,
        disabledDates,
        handleMonthYear,
        handleOpen,
        handleDeleteReservation,
        datepicker
    }
}
}
</script>
  